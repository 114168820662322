.group-861 {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 6px;
    min-height: 58px;
    width: 114px;
  }
  
  .router-status {
    letter-spacing: 0;
    line-height: normal;
    margin-right: 6px;
    min-height: 24px;
    min-width: 110px;
    
  }
  
  .overlap-group-container {
    align-items: flex-start;
    display: flex;
    gap: 6px;
    margin-right: 6px;
    min-width: 96px;
  }
  
  .overlap-group {
    align-items: flex-start;
    background-color: 
  #b3000033;
    border: 1px solid;
    border-color: 
  #b3000099;
    border-radius: 8px;
    display: flex;
    height: 28px;
    min-width: 45px;
    padding: 4px 16px;
  }
  
  .number {
    letter-spacing: 0;
    line-height: normal;
    min-height: 18px;
  }
  
  .overlap-group1 {
    align-items: flex-start;
    background-color: 
  #2d623033;
    border: 1px solid;
    border-color: 
  #2d623099;
    border-radius: 8px;
    display: flex;
    height: 28px;
    min-width: 45px;
    padding: 4px 16px;
  }
  .poppins-medium-black-12px {
    color: white;
    font-family: 'Poppins', Helvetica;
    font-size: 12px;
    font-weight: 500;
    font-style: normal;
  }
  .poppins-medium-whitecustom-12px {
    color: black;
    font-family: 'Poppins', Helvetica;
    font-size: 12px;
    font-weight: 500;
    font-style: normal;
  }

  .poppins-medium-midnight-16px {
    color: white;
    font-family: 'Poppins', Helvetica;
    font-size: 16px;
    font-weight: 500;
    font-style: normal;
  }
  .poppins-medium-blackcustom-16px {
    color: black;
    font-family: 'Poppins', Helvetica;
    font-size: 16px;
    font-weight: 500;
    font-style: normal;
  }

  .poppins-medium-romance-14px {
    font-family: 'Poppins', Helvetica;
    font-size: 14px;
    font-weight: 500;
    font-style: normal;
  }

  .custom-tooltip-light .tooltip-inner {
    background-color: rgba(255, 255, 255, 1); 
    border: 1px solid black;/* Here, change color as per your requirement */
   
}

.custom-tooltip-light .arrow::before {
  border-top-color: rgba(255, 255, 255, 1); /* This is for tooltip positioned at top */
  border-right-color: rgba(255, 255, 255, 1); /* This is for tooltip positioned at right */
  border-bottom-color: rgba(255, 255, 255, 1)red; /* This is for tooltip positioned at bottom */
  border-left-color: rgba(255, 255, 255, 1); /* This is for tooltip positioned at left */
}

.custom-tooltip-dark .tooltip-inner {
  background-color: #2E2E3E !important;
  border: 1px solid black; /* Here, change color as per your requirement */
 
}

.custom-tooltip-dark .arrow::before {
border-top-color: #2E2E3E !important; /* This is for tooltip positioned at top */
border-right-color: #2E2E3E !important; /* This is for tooltip positioned at right */
border-bottom-color: #2E2E3E !important; /* This is for tooltip positioned at bottom */
border-left-color: #2E2E3E !important; /* This is for tooltip positioned at left */
}
