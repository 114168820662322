.bar-color {
    background-color: rgb(0, 0, 0);
  }

  .router-status {
    letter-spacing: 0;
    line-height: normal;
    min-height: 24px;
  }

  .poppins-medium-romance-16px {
    color: white;
    font-family: 'Poppins', Helvetica;
    font-size: 16px;
    font-weight: 500;
    font-style: normal;
  }
  .poppins-medium-romance-16px-blackcustom {
    color: black;
    font-family: 'Poppins', Helvetica;
    font-size: 16px;
    font-weight: 500;
    font-style: normal;
  }
  .poppins-medium-romance-20px {
    font-family: 'Poppins', Helvetica;
    font-size: 20px;
    font-weight: 500;
    font-style: normal;
  }
  .poppins-16px {
    font-family: 'Poppins', Helvetica;
    font-size: 16px;
    font-weight: 600;
    font-style: normal;
  }
  